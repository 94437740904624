// JavaScript Document

// vendor

import jQuery from "jquery";
import "bootstrap";
import Swiper, { Navigation, Autoplay } from "swiper";

// custom

jQuery(document).ready(function () {
    const swiper = new Swiper(".home-swiper", {
        modules: [Navigation, Autoplay],
        slidesPerView: 3,
        spaceBetween: 50,
        loop: true,
        loopFillGroupWithBlank: true,
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        speed: 150,
        breakpoints: {
            300: {
                slidesPerView: 1
            },
            767: {
                spaceBetween: 15,
                slidesPerView: 2
            },
            1024: {
                slidesPerView: 3
            },
            1366: {
                spaceBetween: 15,
                slidesPerView: 6
            },
            1440: {
                slidesPerView: 6
            }
        }
    });

    jQuery(".swiper-container").on("mouseenter", function () {
        console.log("stop autoplay");
        swiper.stopAutoplay();
    });

    jQuery(".swiper-container").on("mouseleave", function () {
        console.log("start autoplay");
        swiper.startAutoplay();
    });

    jQuery(".menu-toggle").on("click", function () {
        jQuery("body").toggleClass("main-menu-overlay-active");
        jQuery("html").toggleClass("overflow-hidden");
    });

    jQuery(".menu-item-has-children").on("click", function () {
        jQuery(this).toggleClass("nav_menu_active");
    });
});
