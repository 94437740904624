
function close_all(type, itemClass) {
    type.forEach((ele) => {
        ele.parentElement.classList.remove(itemClass);
    });
}

const dropdowns = document.querySelectorAll(".menu-primary-menu-container .menu-item-has-children > a");
const nav_container = document.getElementById("site-navigation");

// custom
document.querySelectorAll(".close-icon, .overlay, .menu-toggle").forEach((element) => {
    element.addEventListener("click", function () {
        const body = nav_container;
        body.classList.toggle("toggled");
        close_all(dropdowns, "sub-menu-open");
        console.log("toggled");

    });
});

dropdowns.forEach((element) => {
    element.addEventListener("click", function (e) {
        if (window.innerWidth <= 992) {
            if (!element.parentElement.classList.contains("sub-menu-open")) {
                e.preventDefault();
            }

            // close all dropdowns before opening the correct child dropdowns
            close_all(dropdowns, "sub-menu-open");

            // hook in to the li wrapping the element we just clicked
            let elementTree = element.parentElement;

            // while we're inside of the menu:
            // if the element we are on contains EITHER "menu-item-has-children" or "sub-menu"
            while (elementTree.classList.contains("menu-item")) {
                // if the menu item is a wrapper for a sub-menu, add the sub-menu-open class
                if (elementTree.classList.contains("menu-item-has-children")) {
                    elementTree.classList.add("sub-menu-open");
                }
                elementTree = elementTree.parentElement.parentElement;
            }
        }
    });
});
